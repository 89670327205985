import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`17th May 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">{`The request body is injected automatically in annotated HTTP service when the parameter type is `}<inlineCode parentName="li">{`byte[]`}</inlineCode>{`, `}<inlineCode parentName="li">{`HttpData`}</inlineCode>{`, `}<inlineCode parentName="li">{`String`}</inlineCode>{` or `}<inlineCode parentName="li">{`CharSequence`}</inlineCode>{` regardless of the `}<inlineCode parentName="li">{`content-type`}</inlineCode>{` header. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1584"
        }}>{`#1584`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1745"
        }}>{`#1745`}</a></li>
      <li parentName="ul">{`You can now create a curl command from the `}<inlineCode parentName="li">{`DocService`}</inlineCode>{` using `}<inlineCode parentName="li">{`COPY AS A CURL COMMAND`}</inlineCode>{` button. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/196"
        }}>{`#196`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1747"
        }}>{`#1747`}</a></li>
      <li parentName="ul">{`You can now build your gRPC service without depending on gRPC stubs by extending `}<inlineCode parentName="li">{`AbstractUnsafeUnaryGrpcService`}</inlineCode>{`. This is for advanced users only. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1766"
        }}>{`#1766`}</a></li>
      <li parentName="ul">{`You can now log available ciphers for TLS on startup by setting a system property: `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.dumpOpenSslInfo=true`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1777"
        }}>{`#1777`}</a></li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`You can now use `}<inlineCode parentName="li">{`HttpTracingClient`}</inlineCode>{` even when the client is used outside of Armeria server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1767"
        }}>{`#1767`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1768"
        }}>{`#1768`}</a></li>
      <li parentName="ul">{`You can now get pooled HTTP decode responses. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1770"
        }}>{`#1770`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLogAvailabilityException`}</inlineCode>{` is no longer raised in `}<inlineCode parentName="li">{`HttpTracing(Client|Service)`}</inlineCode>{` when a request timed out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1769"
        }}>{`#1769`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1775"
        }}>{`#1775`}</a></li>
      <li parentName="ul">{`Now, an exception is raised while building a server when TLS is configured but ALPN is not supported. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1772"
        }}>{`#1772`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1774"
        }}>{`#1774`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`:scheme`}</inlineCode>{` and `}<inlineCode parentName="li">{`:authority`}</inlineCode>{` headers are set for every received request in the server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1773"
        }}>{`#1773`}</a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1776"
        }}>{`#1776`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-change",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-change",
        "aria-label": "breaking change permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking change`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`newDecoder()`}</inlineCode>{` in `}<inlineCode parentName="li">{`StreamDecoderFactory`}</inlineCode>{` now takes a `}<inlineCode parentName="li">{`ByteBufAllocator`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1770"
        }}>{`#1770`}</a></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Jackson 2.9.8 -> 2.9.9`}</li>
      <li parentName="ul">{`jsoup 1.11.3 -> 1.12.1`}</li>
      <li parentName="ul">{`tomcat-embed-core 9.0.19 -> 9.0.20`}</li>
      <li parentName="ul">{`Spring Boot`}
        <ul parentName="li">
          <li parentName="ul">{`2.1.4.RELEASE -> 2.1.5.RELEASE`}</li>
          <li parentName="ul">{`1.5.20.RELEASE -> 1.5.21.RELEASE`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['alex-lx', 'anuraaga', 'codefromthecrypt', 'dawnbreaks', 'gquintana', 'hyangtack', 'imasahiro', 'jongmin92', 'minwoox', 'trustin', 'wooyeong']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      